<template>
    <div class="center">
        <b-modal id="sub-menu-list" size='lg' hide-footer no-close-on-backdrop content-class="shadow" title="Edit User"
            ok-title="Accept">
            <div class="row">
                <div class="col-md-4">
                    {{data_menu.nm_menu}}
                    </div>
                <div class="col-md-8">
                    <div class="btn btn-primary  btn-sm float-right mb-1" block
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.add-sub-menu><i class="fa fa-plus"></i> Add
                        Sub Menu</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 m-1" v-for="submenu in data_menu.menu_sub" v-bind:key="submenu.id_menu">
                    <div class="apply-job-package bg-light-warning rounded pt-1 pl-1 pr-1 mr-1">
                        <div class="row ">
                            <div class="col-9">
                                <h5 class="text-muted"><i class="fa fa-list mr-1"></i>{{submenu.nm_menu}}</h5>
                                <small class="text-muted"><i class="fa fa-link  mr-1"></i>{{submenu.url}}</small>
                            </div>
                            <div class="col-3 p-1">
                                <div class="btn btn-outline-warning  btn-sm mr-1" block
                                @click="EditSubMenu(submenu, data_menu)"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.edit-sub-menu><i
                                        class="fa fa-edit"></i> </div>
                                <div class="btn btn-outline-danger  btn-sm" block
                                @click="deleteMenuSub(submenu.id_menu,submenu.id_menu_sub)"
                                ><i class="fa fa-trash"></i> </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </b-modal>
        <EditSubMenu 
        :menuData = menuData 
        :SubMenuData = SubMenuData 
        @get_menu = loadMenu></EditSubMenu>
    </div>
</template>
<script>
  import EditSubMenu from './EditSubMenu.vue'
    import axios from '@/config/Axios';
    import Base from '@/config/Mixins_base';

    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'
    import {
        BModal,
        BButton,
        VBModal,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BCardText,
        BTable,

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        props: {
            data_menu: {}
        },
        data: () => ({
            required,
            menuData : {},
            SubMenuData : {} 
        }),
        mixins: [Base],
        emits: ["get_menu"],
        components: {
            BModal,
            BButton,
            VBModal,
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BCardText,
            vSelect,
            BTable,
            EditSubMenu
        },
        methods: {
            EditSubMenu(submenu, data_menu){
                this.menuData  = data_menu;
                this.SubMenuData  = submenu;
            },
            loadMenu(){
                self.$emit("get_menu", "");
            },
            async deleteMenuSub(id_menu,menu_key ) {
                if (confirm("Apakan Anda yakin akan menghapus data ini..?")) { 
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/acl/menu/delete_sub',
                        data: {
                            id_menu : id_menu,
                            menu_key : menu_key
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) { 
                        self.$emit("get_menu", "");
                        self.notification('primary', "info", response.data.message);
                        self.$root.$emit('bv::hide::modal', 'sub-menu-list')

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
                }
            },
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$root.$emit('bv::hide::modal', 'sub-menu-list')
                      
                    }
                })
            },
        },

    }
</script>